
import React from "react";
import styled from "styled-components";
import { Card } from "../../components/card";
// import { Input } from "../../components/input";
import { CustomText, CustomSpan } from "../../components/text";
import OnboardingLandingImage from "../../images/pictures/library.jpg"
import RewardIcon from "../../images/icons/reward-star.svg";
import ClockIcon from "../../images/icons/clock.svg";
import AdvertisingIcon from "../../images/icons/megaphone.svg";
import CrystalIcon from "../../images/icons/crystal.svg";
import { Input, TextArea } from "../../components/input";
import { Button } from "../../components/button";
import Footer from "../../components/footer";
import "../index.css"
import HeaderMeta from "../../components/metatags/head";
class OnboardingBonus extends React.Component {


    render() {
        return (
            <>
                <HeaderMeta title="Onboarding bonus" description="Earn GHS 100 for every 30 mins of video content."/>
                <OnboardingBonusContainer>
                    {/* Onboarding bonus */}
                    <Card className="landing" width="100%" height="85%" radius="0">
                        <Card padding="40px 5%" shadow="20px 1px 3px #eee0e0a" radius="20px" background="#fff" width="60%">
                            <CustomText fontColor="#1d1d1f" fontMargin="1em 0" fontSize="30px" textAlign="left">
                                Onboarding Bonus
                            </CustomText>
                            <CustomText fontColor="#1d1d1f" fontMargin="0.5em 0" fontSize="55px" textAlign="left">
                                Warm welcome from Skideo. We are priviledged to have you on board.
                            </CustomText>
                            <CustomText fontColor="#515154" fontMargin="1em 0" fontSize="40px" fontWeight="600" textAlign="left">
                                At Skideo, we pride ourselves in working with the best. We believe you're one.
                            </CustomText>
                        </Card>
                    </Card>

                    <Card className="body1" background="#f8edeb" padding="20px" radius="0">
                        <Card width="70%" display="block" margin="5em auto" radius="15px" padding="20px" textAlign="center">
                            <img src={RewardIcon} width="60px" style={{ display: "block", margin: "10px auto" }} alt="bonus" />
                            <CustomText fontColor="#1d1d1f" fontMargin="0.5em 0" fontSize="40px" fontWeight="600" >
                                Bonus
                            </CustomText>
                            <CustomText width="80%" fontColor="#515154" fontMargin="1em auto"
                                fontDisplay="block" fontSize="40px" fontWeight="normal" textAlign="left">
                                You stand a change to earn instant bonuses for the first topics you create on Skideo.
                                That way, you kill 2 birds with 1 stone;
                                <CustomSpan fontSize="40px"> own the content and earn more on that. </CustomSpan>
                            </CustomText>

                        </Card>
                    </Card>
                    <Card className="body2" background="#edf2fb" padding="20px" radius="0" display="flex">

                        <Card width="40%" display="block" margin="5em auto" radius="15px" padding="20px" textAlign="center">
                            <img src={ClockIcon} width="60px" style={{ display: "block", margin: "10px auto" }} alt="time" />
                            <CustomText fontColor="#1d1d1f" fontMargin="0.5em 0" fontSize="40px" fontWeight="600" >
                                30 mins min
                            </CustomText>
                            <CustomText width="80%" fontColor="#515154" fontMargin="1em auto"
                                fontDisplay="block" fontSize="40px" fontWeight="normal" textAlign="left">
                                Just 30 mins of quality video contents in a topic is the minimum.
                                Just make sure each this is broken down into at least 12 mins long videos short videos.
                                <CustomSpan fontSize="40px"> Earn GHS 100 for every 30 mins of video content.</CustomSpan>
                            </CustomText>

                        </Card>

                        <Card width="40%" display="block" margin="5em auto" radius="15px" padding="20px" textAlign="center">
                            <img src={AdvertisingIcon} width="60px" style={{ display: "block", margin: "10px auto" }} alt="advertisement" />
                            <CustomText fontColor="#1d1d1f" fontMargin="0.5em 0" fontSize="40px" fontWeight="600" >
                                Be out there
                            </CustomText>
                            <CustomText width="80%" fontColor="#515154" fontMargin="1em auto"
                                fontDisplay="block" fontSize="40px" fontWeight="normal" textAlign="left">
                                Through this opportunity, Skideo promotes your brand and puts you
                                out there and as such you impact more audience that you thought.
                                <CustomSpan fontSize="40px"> Free brand promotion! </CustomSpan>
                            </CustomText>

                        </Card>
                    </Card>

                    <Card className="body3" padding="20px" radius="0">
                        <Card shadow="0px 12px 30px #923D411A" width="70%" display="block" margin="5em auto" radius="15px" padding="20px" textAlign="center">
                            <img src={CrystalIcon} width="60px" style={{ display: "block", margin: "10px auto" }} alt="reward" />
                            <CustomText fontColor="#1d1d1f" fontMargin="0.5em 0" fontSize="40px" fontWeight="600" >
                                Bonus
                            </CustomText>
                            <CustomText width="80%" fontColor="#515154" fontMargin="1em auto"
                                fontDisplay="block" fontSize="40px" fontWeight="normal" textAlign="left">
                                Be a part of this great revolution that Skideo is creating!
                                <CustomSpan fontSize="40px"> We are glad you took interest.</CustomSpan>
                            </CustomText>

                        </Card>
                    </Card>

                    <Card className="tail" padding="20px" radius="0">
                        <Card width="60%" display="block" margin="5em auto" radius="15px" padding="20px" textAlign="center">
                            <img src={RewardIcon} width="60px" style={{ display: "block", margin: "10px auto" }} alt="reward" />
                            <CustomText fontColor="#1d1d1f" fontMargin="0.5em 0" fontSize="40px" fontWeight="600" >
                                Want to be a changemaker?
                            </CustomText>

                            <CustomText width="100%" fontColor="#515154" fontMargin="1em auto"
                                fontDisplay="block" fontSize="30px" fontWeight="normal" textAlign="left">
                                Apply to stand a change of getting bonuses for your first 5 topics on Skideo.
                            </CustomText>

                            <CustomText fontColor="#013a63" fontMargin="0.5em 0" fontSize="40px" fontWeight="600">
                                Full name
                            </CustomText>
                            <Input placeholder="Full name" height="50px" size="30px" weight="normal" width="100%" />
                            <CustomText fontColor="#283618" fontMargin="0.5em 0" fontSize="40px" fontWeight="600" >
                                Email
                            </CustomText>
                            <Input placeholder="Skideo Account Email" height="50px" size="30px" weight="normal" width="100%" />

                            <CustomText fontColor="#360506" fontMargin="0.5em 0" fontSize="40px" fontWeight="600" >
                                Telephone Number
                            </CustomText>
                            <Input placeholder="Telephone number" height="50px" size="30px" weight="normal" width="100%" />

                            <CustomText fontColor="#032730" fontMargin="0.5em 0" fontSize="40px" fontWeight="600" >
                                Recording technique
                            </CustomText>
                            <TextArea width="100%" margin="25px auto" placeholder="How to you plan to record" resize="vertical" size="30px"></TextArea>

                            <Card display="flex" alignItems="center">
                                <Input type="checkbox" width="20px" margin="10px" />
                                <CustomText fontColor="#032730" fontMargin="0.5em 0" fontSize="30px" fontWeight="600" >
                                    Would you like to receive the bonus compensation if selected?
                                </CustomText>
                            </Card>
                            <Card display="flex" alignItems="center">
                                <Input type="checkbox" width="20px" margin="10px" />
                                <CustomText fontColor="#032730" fontMargin="0.5em 0" fontSize="30px" fontWeight="600" >
                                    Do you agree to the terms and conditions of the bonus?
                                </CustomText>
                            </Card>

                            <Button>
                                Submit
                            </Button>
                        </Card>
                    </Card>
                    <Footer />
                </OnboardingBonusContainer>
            </>
        )
    }

}

export default OnboardingBonus;

const OnboardingBonusContainer = styled.div`
    height: 100vh;

    .landing{
        &>div{
            position: absolute;
            top: 40vh;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
        }
        background-image: radial-gradient(circle at center center, 
            rgba(33,33,33,0),rgb(33,33,33)),
            repeating-linear-gradient(135deg, 
                rgb(33,33,33) 0px, 
                rgb(33,33,33) 1px,transparent 1px, 
                transparent 4px),
            repeating-linear-gradient(45deg, rgb(56,56,56) 0px, 
            rgb(56,56,56) 5px,transparent 5px, transparent 6px),
            linear-gradient(90deg, rgb(33,33,33),rgb(33,33,33)
            );
            background-image: repeating-linear-gradient(90deg, rgba(13, 13, 13,0.09) 0px, rgba(13, 13, 13,0.09) 36px,rgba(229, 229, 229,0.09) 36px, rgba(229, 229, 229,0.09) 72px,transparent 72px, transparent 108px,rgba(163, 163, 163,0.09) 108px, rgba(163, 163, 163,0.09) 144px,rgba(21, 21, 21,0.09) 144px, rgba(21, 21, 21,0.09) 180px),repeating-linear-gradient(90deg, rgba(0,0,0,0.08) 0px, rgba(0,0,0,0.08) 14px,transparent 14px, transparent 28px,rgba(0,0,0,0.08) 28px, rgba(0,0,0,0.08) 42px,transparent 42px, transparent 56px,rgba(0,0,0,0.08) 56px, rgba(0,0,0,0.08) 70px),repeating-linear-gradient(90deg, rgba(0,0,0,0.08) 0px, rgba(0,0,0,0.08) 23px,transparent 23px, transparent 46px,rgba(0,0,0,0.08) 46px, rgba(0,0,0,0.08) 69px,transparent 69px, transparent 92px,rgba(0,0,0,0.08) 92px, rgba(0,0,0,0.08) 115px),repeating-linear-gradient(90deg, rgba(0,0,0,0.04) 0px, rgba(0,0,0,0.04) 6px,transparent 6px, transparent 12px,rgba(0,0,0,0.04) 12px, rgba(0,0,0,0.04) 18px,transparent 18px, transparent 24px,rgba(0,0,0,0.04) 24px, rgba(0,0,0,0.04) 30px),linear-gradient(90deg, rgb(212, 109, 30),rgb(176, 117, 193));

        background-image: url(${OnboardingLandingImage});
        background-position: center; /* Center the image */
        /* background-repeat: no-repeat; Do not repeat the image */
  /* background-size: cover; Resize the background image to cover the entire container */

    }

    @media screen and (max-width: 480px) {
        

        .landing{
            &>div{
                width: 90%;

                h1:first-child{
                    font-size: 20px;
                }
                h1:nth-child(2){
                    font-size: 35px;

                }
                h1:last-child{
                    font-size: 25px;
                }
            }

        }

        .body1{
            padding: 20px 0;
            &>div{
                width: 90%;
                padding: 1em 0;

                h1{
                    font-size: 25px;
                }

                h1:last-child{
                    font-size: 20px;
                    span{
                        font-size: 18px;
                    }
                }
            }
        }

        .body2{
            display: block;

            padding: 20px 0;
            &>div{
                width: 90%;
                padding: 1em 0;

                h1{
                    font-size: 25px;
                }

                h1:last-child{
                    font-size: 20px;
                    span{
                        font-size: 18px;
                    }
                }
            
        }


        }

        .body3{
            padding: 20px 0;
            &>div{
                width: 90%;
                padding: 1em 0;

                h1{
                    font-size: 25px;
                }

                h1:last-child{
                    font-size: 20px;
                    span{
                        font-size: 18px;
                    }
                }
            }
        }

        .tail{

            img{
                width: 50px;
            }

            padding: 20px 0;
            &>div{
                width: 90%;
                padding: 1em 0;

                h1{
                    font-size: 20px;
                }

                input, textarea{
                    font-size: 20px;
                    border: solid 1.5px #010409;

                    &:focus {
                        border: 2px solid #923D41;
                    }
                }

                input[type="checkbox"]{
                    width: 15%;
                }
            }
            
        }


    

        

    }

`